@import './../../stylusMixin/libs'
normalize()

a:focus
    outline: 0

html
    height 100%
    min-width 480px

body
    position relative
    min-height 100%
    font-family "PT Sans"
.main
    display block
    center(960px)
    background-color #FFF
    position relative
    height 100%
    
