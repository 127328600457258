.menu
    center(960px)
    padding-top 35px
.menuLogo
    display inline-block
    vertical-align top
    margin-right 82px
.menuItems
    display inline-block

.menuItemsTop
    display block
    margin-bottom 10px
    a
        color #333
        font-family "PT Sans"
        font-size 18px
        font-weight 400
        line-height 22px
        text-decoration underline
        margin-right 25px
        &:hover
            color #9c151a
    .active
        color #9c151a
        text-decoration none
.menuItemsBottom
    display block
    a
        color #333
        font-family "PT Sans"
        font-size 13px
        font-weight 400
        line-height 22px
        text-decoration underline
        margin-right 20px
        &:hover
            color #ed1c24
            text-decoration none
    .active
        color #ed1c24
        text-decoration none
