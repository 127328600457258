.footer
    display block
    height 307px
    background-image url("../img/footerBG.png")


.footerWrap
    center(960px)
    padding-top 40px

.footerMenu
    display block
    text-align center
    margin-bottom 80px
    &Wrap
        display inline-block
        a
            color #f7f7f7
            font-family "PT Serif"
            font-size 18px
            font-weight 700
            font-style italic
            line-height 22px
            margin-right 25px
            &:after
                display inline-block
                content '•'
                padding-left 25px
            &:last-child
                margin-right 0px
                &:after
                    display none
            &:hover
                text-decoration none
        .active
            text-decoration none


.footerSocial
    col(3/8)
    padding-left 35px
    background-image inline("iconColos.png")
    background-repeat no-repeat
    background-position left top

.footerCopyright
    display block
    color #f7f7f7
    font-family "PT Sans"
    font-size 15px
    font-weight 400
    line-height 22px
    margin-bottom 15px

.footerSocialLink
    display block
    clear fix
    margin-bottom 25px

.footerSocialLinkFB
    display inline-block
    height 25px
    margin-right 15px
    a
        display inline-block
        background-image inline("iconFB.png")
        background-repeat no-repeat
        background-position left center
        //background-size cover
        padding-left 20px
        color #f7f7f7
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 25px
        height 25px
        text-decoration underline
        &:hover
            text-decoration none

.footerSocialLinkVK
    display inline-block
    margin-right 15px
    height 25px
    a
        display inline-block
        background-image inline("iconVK.png")
        background-repeat no-repeat
        background-position left center
        //background-size cover
        padding-left 35px
        color #f7f7f7
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 25px
        height 25px
        text-decoration underline
        &:hover
            text-decoration none

.footerSocialLinkInstagramm
    display inline-block
    height 25px
    a
        display inline-block
        background-image inline("iconInstagramm.png")
        background-repeat no-repeat
        background-position left center
        //background-size cover
        padding-left 25px
        color #f7f7f7
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 25px
        height 25px
        text-decoration underline
        &:hover
            text-decoration none

.footerDesign
    display block
    border-top 1px solid #676566
    margin-left -35px
    padding-top 17px
    a
        display inline-block
        background-image inline("iconFAKE.png")
        background-repeat no-repeat
        background-position left center
        padding-left 62px
        color rgba(#f7f7f7,0.3)
        font-family "PT Sans"
        font-size 13px
        font-weight 400
        line-height 22px
        text-decoration none
        span
            text-decoration underline
        &:hover
            text-decoration none
            span
                text-decoration none

.footerContact
    col(2/8)
    color #f7f7f7
    font-family "PT Sans"
    font-weight 400
    font-size 15px
    line-height 22px
    span
        display block
        font-size 24px
        line-height 30px
        margin-bottom 5px
        a
            text-decoration none
    a
        color #f7f7f7
        &:hover
            text-decoration none
.footerAddress
    col(3/8)
    color #f7f7f7
    font-family "PT Sans"
    font-weight 400
    font-size 15px
    line-height 22px
    span
        display block
        font-size 24px
        line-height 30px
        margin-bottom 5px

    a
        color #f7f7f7
        &:hover
            text-decoration none
.footerLogos
    display block
    padding-top 20px
    clear fix

.footerLogoRST
    display inline-block
    background-image inline("iconRST.png")
    background-repeat no-repeat
    background-position center center
    size 60px
    margin-right 15px
.footerLogoEAC
    display inline-block
    background-image inline("iconEAC.png")
    background-repeat no-repeat
    background-position center center
    size 60px
