.offers
    display block
    background-image url("../img/tarelkaBG.png")
    background-repeat no-repeat
    background-position left 20% center
    height 700px
.offersWrap
    center(960px)
    padding-top 80px

.offerPhoto
    col(1/2)


.offerItems
    col(1/2)

.offerItem
    display block
    margin-bottom 50px
    h2
        display inline-block
        color #333
        font-family "PT Sans"
        font-size 30px
        font-weight 400
        line-height 39px
        border-bottom 1px solid #333
        margin-bottom 20px
    p
        color #333
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 22px
        margin-bottom 40px
