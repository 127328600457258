.recepts
    display block
    margin-bottom 130px
    clear fix

.receptBig
    col(3/8)
.receptBigPhoto
    display block
    margin-bottom 30px
.receptBigText
    color #333
    font-size 15px
    font-weight 400
    font-family "PT Sans"
    a
        display block
        color #3b6bcd
        margin-bottom 10px
.receptSmall
    col(2/8)
.receptSmallPhoto
    display block
    margin-bottom 30px
.receptSmallText
    color #333
    font-size 15px
    font-weight 400
    font-family "PT Sans"
    a
        display block
        color #3b6bcd
        margin-bottom 10px
.receptLink
    col(3/8)
    padding-left 150px
    background-image inline("iconRecept.png")
    background-repeat no-repeat
    background-position left 100px top
    color #333
    font-family "PT Sans"
    font-weight 400
    font-size 15px
    a
        display block
        margin-bottom 20px
        color #333
        font-size 30px
        text-decoration none
        span
            display inline
            transition .3s all
            border-bottom 1px solid #333
        &:hover
            span
                border-bottom 1px solid transparent
