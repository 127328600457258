.label
    display inline-block
    vertical-align middle
    color #333
    font-family "PT Sans"
    font-size 15px
    font-weight 400
    line-height 22px
    padding-left 10px
    margin-bottom 5px
    span
        color #999
        font-style italic
.labelRadio
    display inline-block
    cursor pointer
    font-family "PT Sans"
    font-size 18px
    line-height 22px
    color #333
    font-weight 400
    .iradio
        margin-right 10px
    span
        display inline-block
        vertical-align middle
        color #ed1c24
        font-style italic
    strong
        display block
        font-weight normal
        padding-left 30px
        font-size 15px
        line-height 26px
.labelCheckbox
    display inline-block
    vertical-align middle
    cursor pointer
    font-family Arial, sans-serif
    font-size 13px
    font-weight 400
    line-height 20px
    color #333
    &Input
        display table-cell
        vertical-align middle
    &Text
        display table-cell
        vertical-align middle
        padding-top 1px
        padding-left 12px
    &TextCons
        display table-cell
        color #000
        vertical-align middle
        font-family 'CorporateCons'
        padding-top 1px
        font-size 20px
        padding-left 12px

.labelSelect
    display inline-block
    vertical-align middle
    cursor pointer
    font-family Arial, sans-serif
    font-size 13px
    font-weight 400
    color #333
    &Input
        display table-cell
        vertical-align middle
        padding-left 12px
    &Text
        display table-cell
        vertical-align middle
        padding-top 1px
