.delivery
    col(3/8)
    margin-bottom 130px
.deliveryHeader
    display block
    margin-bottom 10px
    clear fix

.deliveryHeader
    display block
    h2
        color #333
        font-family "PT Sans"
        font-size 30px
        font-weight 400
        line-height 30px
        margin-bottom 20px
    span
        display inline-block
        border-bottom 1px solid #333
    p
        display block
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 22px
        margin-bottom 15px
    .red
        color #ed1c24
.deliveryMap
    margin-bottom 20px
.deliveryPhone
    color #333
    font-family "PT Sans"
    font-weight 400
    font-size 24px
    line-height 30px
    span
        font-size 15px
        line-height 22px
