.suggestion
    display block
    margin-bottom 70px
    clear fix

.suggestionHeader
    col(2/9)
    color #333
    font-family "PT Serif"
    font-size 24px
    font-weight 700
    font-style italic
    line-height 36px
.suggestionItems
    col(7/9)


.suggestionItem
    col(1/3)

.suggestionItemPhoto
    display block
    text-align center
    margin-bottom 25px
    img
        width 150px

.suggestionItemName
    display block
    text-align center
    color #333
    font-family "PT Serif"
    font-size 18px
    font-weight 700
    font-style italic
    line-height 22px
    margin-bottom 25px
    clear fix

.suggestionItemDescription
    display block
    text-align center
    color #333
    font-family "PT Sans"
    font-size 13px
    font-weight 400
    line-height 24px
    button
        margin-left auto
        margin-right auto
        margin-bottom 20px
