.contact
    center(960px)
    padding-top 70px

.contactHeader
    display block
    margin-bottom 50px
    text-align center
    clear fix
    color #333
    font-family "PT Serif"
    font-size 40px
    font-weight 700
    font-style italic
    line-height 22px

.contactRow
    display block
    margin-bottom 50px
    clear fix

.contactColumn
    col(3/8,1/8)

.contactPhone
    display block
    margin-bottom 10px
    font-family "PT Sans"
    font-weight 400
    color #333
    font-size 30px
    line-height 30px
    span
        font-size 13px
        line-height 22px
.contactTime
    font-family "PT Sans"
    font-weight 400
    color #333
    font-size 15px
    line-height 22px
    span
        display block
        margin-top 10px
        color #ed1c24

.contactAddress
    display block
    p
        display block
        margin-bottom 15px
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        color #333
        line-height 22px
        a
            color #3b6bcd

.contactMap
    display block
    width 100%
    height 400px
    margin-bottom 80px
    clear fix

.contactForm
    col(4/6,1/6)

.contactFormHeader
    display block
    margin-bottom 40px
    text-align center
    color #333
    font-family "PT Serif"
    font-size 30px
    font-weight 700
    font-style italic
    line-height 36px

.contactFormText
    display block
    margin-bottom 40px
    text-align center
    color #333
    font-family "PT Sans"
    font-size 18px
    font-style italic
    line-height 27px
    clear fix

.contactFormSocial
    display block
    text-align center
    margin-bottom 70px
    clear fix


.contactFormSocialFB
    display inline-block
    height 25px
    margin-right 25px
    a
        display inline-block
        background-image inline("iconFBBlack.png")
        background-repeat no-repeat
        background-position left center
        //background-size cover
        padding-left 20px
        color #3b6bcd
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 25px
        height 25px
        text-decoration underline

.contactFormSocialVK
    display inline-block
    margin-right 25px
    height 25px
    a
        display inline-block
        background-image inline("iconVKBlack.png")
        background-repeat no-repeat
        background-position left center
        //background-size cover
        padding-left 35px
        color #3b6bcd
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 25px
        height 25px
        text-decoration underline

.contactFormSocialInstagramm
    display inline-block
    height 25px
    a
        display inline-block
        background-image inline("iconInstagrammBlack.png")
        background-repeat no-repeat
        background-position left center
        //background-size cover
        padding-left 25px
        color #3b6bcd
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 25px
        height 25px
        text-decoration underline

.contactFormItems
    col(4/6,1/6)
    margin-bottom 70px
.contactFormItem
    display block
    margin-bottom 25px
    clear fix
