.catalogItemPopupWrap
    display block
    position fixed
    height 100%
    width 100%
    min-height 650px
    overflow hidden
    top 60px
.catalogItemPopupWrapOpen
    z-index 9000
.catalogItemPopup
    display block
    position absolute
    background-color #FFF
    width 960px
    top center
    left center
    z-index 0
    border-top 2px solid #000
    border-bottom 2px solid #000
    padding 30px 20px
    transition .5s all
    visibility hidden
    opacity 0
    clear fix
.catalogItemPopupLeft
    &:before
        display block
        position absolute
        content ' '
        top -1.47rem
        left 100px
        size 15px
        background-image inline("iconTriangle.png")
        background-repeat no-repeat
        background-position center bottom
        background-color #FFF
.catalogItemPopupCenter
    @extend .catalogItemPopupLeft
    &:before
        left 350px
.catalogItemPopupRight
    @extend .catalogItemPopupLeft
    &:before
        left 595px
.catalogItemPopupShow
    opacity 1
    visibility visible
    z-index 10000

.catalogItemPopupClose
    display block
    position absolute
    top -2px
    right 0
    width 40px
    background-color #fff
    height 2px
.buttonItemPopupClose
    display block
    position absolute
    top -6px
    right 0
    size 16px
    border 0
    outline 0
    background-color transparent
    background-image inline("iconItemClose.png")
    background-repeat no-repeat
    transition .3s all
    &:hover
        background-image inline("iconItemCloseActive.png")
.catalogItemPopupRow
    display block
    clear fix
.catalogItemPopupColumn
    col(1/2)

.catalogItemPopupName
    display block
    margin-bottom 10px
    color #333
    font-family 'PT Serif'
    font-size 30px
    font-weight 700
    font-style italic
    line-height 30px
.catalogItemPopupAdd
    display block
    margin-bottom 15px
    color #333
    font-family PTSans
    font-size 13px
    font-style italic
    line-height 17px
    min-height 17px

.catalogItemPopupButton
    display block
    margin-bottom 35px
    clear fix
    button
        display inline-block
        margin-right 15px
.catalogItemPopupDescription
    color #333
    font-family 'PT Sans'
    font-size 15px
    font-style italic
    line-height 22px

.catalogItemPopupSuggestion
    display block
    padding-top 80px
    clear fix
.catalogItemPopupSuggestionHeader
    col(1/8)
    color #333
    font-family 'PT Serif'
    font-size 18px
    font-weight 700
    font-style italic
    line-height 22px
.catalogItemPopupSuggestionItems
    col(7/8)

.catalogItemPopupSuggestionItem
    col(1/3)

.catalogItemPopupSuggestionItemPhoto
    col(1/3)
    img
        width 80px
.catalogItemPopupSuggestionItemText
    col(2/3)
    padding-left 10px
    h2
        font-family 'PT Serif'
        font-size 15px
        font-weight 700
        font-style italic
        line-height 18px
        margin-bottom 8px
    p
        font-family 'PT Sans'
        font-size 13px
        font-weight 400
        line-height 20px
