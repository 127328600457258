.cart
    center(960px)
    padding-top 70px
    clear fix
.cartSuccess
    min-height calc(100vh - 468px)
.cartHeader
    display block
    position relative
    text-align center
    color #333
    font-family "PT Serif"
    font-size 40px
    font-weight 700
    font-style italic
    margin-bottom 60px
    clear fix
    p
        margin-top 15px
        font-family "PT Sans"
        font-size 18px
        font-weight 400
        line-height 30px
.cartBack
    display block
    position absolute
    top calc(50% - 11px)
    background-image inline("iconArrowLeft.png")
    background-repeat no-repeat
    background-position left center
    height 22px
    width 200px
    text-align left
    padding-left 20px
    a
        display block
        color #333
        font-family "PT Sans"
        font-size 13px
        font-weight 400
        line-height 22px
        font-style normal
        text-decoration underline

.cartItems
    display block
    padding 10px 20px
    border-top 2px solid #000
    border-bottom 2px solid #000
    margin-bottom 50px
    clear fix

.catalogItemsRow
    display block
    position relative
    clear fix
.cartItem
    display block
    margin-bottom 40px
    clear fix
    &:last-child
        margin-bottom 0px

.cartItemPhoto
    col(1/7)
    img
        width 100%

.cartItemName
    col(2/7)
    padding-top 35px
    color #333
    font-family "PT Serif"
    font-size 18px
    font-weight 700
    font-style italic
    line-height 22px
.cartItemQty
    col(2/7)

.cartItemPrice
    col(1/7)
    padding-top 35px
    text-align right
    color #333
    font-family "PT Serif"
    font-size 18px
    font-weight 700
    font-style italic
    line-height 22px
.cartItemQtyPrices
    display block
    padding-top 35px
    float left
.cartItemQtyGramm
    font-family "PT Sans"
    font-weight 400
    color #333
    font-size 18px
    line-height 22px
.cartItemQtyPrice
    font-family "PT Sans"
    font-weight 400
    color #999
    font-size 13px
    line-height 18px
.cartItemQtyInput
    display block
    padding-top 7px
    float left
    padding-left 10px
.cartItemDelete
    col(1/7)
    text-align right
    padding-top 41px

.cartItemsFooter
    display block
    margin-bottom 80px
    clear fix


.cartItemsFooterDelivery
    col(2/4)
    font-family "PT Sans"
    font-size 15px
    font-weight 400
    line-height 22px
    color #333
    a
        display inline-block
        color #3b6bcd
        text-decoration none
        border-bottom 1px dashed #3b6bcd
        line-height 15px
        &:hover
            color lighten(#3b6bcd, 20%)
.cartItemsFooterPrice
    col(1/4)
    text-align right
    padding-top 13px
    padding-right 20px
    color #333
    font-family "PT Serif"
    font-size 24px
    font-weight 700
    font-style italic
    line-height 22px
    span
        margin-right 30px
.cartItemsFooterButton
    col(1/4)
    button
        width 100%
