.inputWithButton
    display block
    position relative
    height 30px
    clear fix
    .buttonInput
        display block
        position absolute
        top 0
        right 0
        background-image linear-gradient(to bottom, #747474, #5C5C5C)
        border 0
        size 30px
        padding 1px 0 0 0
        outline 0
        svg
            size 28px
            stroke #fff
            fill none
    .input
        padding-right 35px

.inputSearch
    min-width 270px
