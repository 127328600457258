.header
    display block
    //background-color #000
    background-image inline('headerBG.png')
    height 50px
    transition transform 200ms linear;
.headerWrap
    center(960px)
.headerLeft,
.headerRight
    col(1/2)
    height 50px
.headerPhone
    display block
    margin-top 16px
    color white
    font-family "PT Sans"
    font-weight 400
    line-height 30px
    font-size 13px
    line-height 18px
    span
        font-size 18px
        a
            text-decoration none
            color white

.headerCart
    display block
    height 50px
    vertical-align middle
    margin-top 12px
    float right
    span
        display inline-block
        color white
        font-family "PT Serif"
        font-size 13px
        font-weight 700
        font-style italic
        line-height 30px
        padding-left 22px
        background-image inline("iconCart.png")
        background-repeat no-repeat
        background-position left center
        vertical-align middle
    button
        display inline-block
        margin-left 20px
    &Button
        margin-top 10px

.headroom--unpinned, .headroom--not-top
    width 100%
    position fixed
    z-index 1000
    transform translateY(0%)
