.horeca
    center(960px)
    padding-top 70px
.horecaMenu
    display block
    text-align center
    margin-bottom 30px
    color #333
    font-family "PT Serif"
    font-size 13px
    font-weight 700
    font-style italic
    a
        display inline-block
        position relative
        color #333
        font-family "PT Serif"
        font-size 13px
        font-weight 700
        font-style italic
        line-height 13px
        margin 0 15px
        padding 0
        // &:after
        //     display inline-block
        //     content '•'
        //     padding-left 15px
        &:last-child
            margin-right 0px
            &:after
                display none
        &:hover
            text-decoration none
    .active
        color #fff
        text-decoration none
        &:after
            display block
            position absolute
            top -3px
            left -10px
            width calc(100% + 20px)
            height 20px
            z-index -1
            content ' '
            background-color #ed1c24
            border-radius 11px

    .blue
        text-decoration none
        color #3b6bcd
        border-bottom 1px dashed #3b6bcd


.horecaBanner
    display block
    height 340px
    margin-bottom 20px
    clear fix

.horecaBannerImage
    display block
    position relative
    height 340px
    background-repeat no-repeat
    background-size cover
    clear fix

.horecaBannerHeader
    display block
    position absolute
    top center
    left 15%
    color #fff
    h2
        font-size 40px
        font-weight 700
        font-style italic
        line-height 52px
        font-family "PT Serif"
        margin-bottom 20px
        color #fff
    p
        font-family "PT Sans"
        font-size 18px
        font-weight 400
        line-height 23px
        margin-bottom 50px
        color #fff


.horecaLogo
    display block
    vertical-align middle
    margin-bottom 70px
    clear fix
.horecaLogoText
    display inline-block
    vertical-align middle
    color #333
    font-family "PT Sans"
    font-size 18px
    font-weight 400
    line-height 23px
    margin-right 25px
.horecaLogoItem
    display inline-block
    vertical-align middle
    margin-right 35px
    &:last-child
        margin-right 0px

.horecaContent
    col(2/4,1/4)
    margin-bottom 70px
    h3
        display block
        margin-bottom 15px
        color #333
        font-family "PT Sans"
        font-size 18px
        font-style italic
        line-height 27px
        font-weight 400
    p
        display block
        margin-bottom 15px
        font-size 15px
        font-weight 400
        line-height 22px
        color #333
        font-family "PT Sans"

.horecaForm
    col(2/4,1/4)

.horecaFormHeader
    display block
    margin-bottom 40px
    text-align center
    color #333
    font-family "PT Serif"
    font-size 30px
    font-weight 700
    font-style italic
    line-height 36px
.horecaFormItems
    display block
    margin-bottom 70px
    clear fix

.horecaFormItem
    display block
    margin-bottom 25px
    clear fix
