
.about
    center(960px)
    padding-top 70px

.aboutVideo
    display block
    margin-bottom 70px
    clear fix

.aboutContent
    display block
    margin-bottom 70px
    clear fix

.aboutText
    col(1/2)
    h2
        display block
        margin-bottom 30px
        font-size 40px
        font-weight 700
        font-style italic
        line-height 52px
    p
        display block
        margin-bottom 10px
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 22px
    span
        display block
        font-family "PT Sans"
        font-size 13px
        font-weight 400
        line-height 20px

.aboutPhotos
    display block
    margin-bottom 120px
    clear fix

.aboutPhotoColumn
    col(1/2)
    img
        margin-bottom 15px
        color #333
        font-family "PT Serif"
        font-size 40px
        font-weight 700
        font-style italic

        
