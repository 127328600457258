.ui-timepicker-wrapper
    overflow-y auto
    height auto
    margin-left 5px
    width 6.5em
    background #fff
    border 1px solid #ddd
    outline none
    z-index 10001
    font-family Arial, sans-serif
    font-size 13px
    line-height 17px
    margin 0
    &.ui-timepicker-with-duration
        width 13em

.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30,
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60
    width 11em

.ui-timepicker-list
    margin 0
    padding 0
    list-style none
    &:hover
        .ui-timepicker-duration
            color #888
        .ui-timepicker-selected
            background #fff
            color #000
    li
        padding 3px 0 3px 5px
        cursor pointer
        white-space nowrap
        color #000
        list-style none
        margin 0

.ui-timepicker-duration
    margin-left 5px
    color #888

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover
    background #1980EC
    color #fff

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration
    color #ccc

.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled
    color #888
    cursor default

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled
    background #f2f2f2
