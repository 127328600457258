.tooltip
    display inline-block
    position relative
    margin-left 5px
    svg
        size 15px
    .tooltipHover
        display none
        position absolute
        font-family Arial, sans-serif
        font-size 13px
        line-height 20px
        padding 18px
        background-color #EFEFEF
        top calc(100% + 8px)
        right -23px
        color #444444
        margin-left -1px
        box-shadow 4px px 0px rgba(0,0,0,.2)
        z-index 1000
        p
            white-space nowrap
            //margin-bottom 5px
        &:before
            display block
            position absolute
            content ''
            triangle right-iso pointing-up
            width 20px
            right 20px
            top -10px
            background-color #EFEFEF
    &:hover
        .tooltipHover
            display block
