.form
    display block
    clear fix
.formItem
    display block
    margin-bottom 10px
    clear fix

.formItemCenter
    display block
    text-align center
    margin-bottom 10px
    clear fix

.formItemNoMargin
    margin-bottom 0px
