.cartForm
    display block
    col(2/4,1/4)
    clear fix

.cartFormHeader
    display block
    color #333
    font-family "PT Serif"
    font-size 24px
    font-weight 700
    font-style italic
    line-height 36px
    margin-bottom 40px
    clear fix
    .cartFormHeaderSelect
        display inline-block
        vertical-align middle
        margin-left 30px
.cartFormDeliveryType
    display block
    margin-bottom 20px
    clear fix
.cartFormDeliveryItem
    display block
    margin-bottom 24px
    clear fix
.cartFormDeliveryPerson
    display block
    margin-bottom 60px
    clear fix

.cartFormPay
    display block
    margin-bottom 60px
    clear fix
.cartFormTotal
    display block
    margin-bottom 60px
    clear fix
.cartFormTotalPrice
    display block
    margin-bottom 35px
    color #333
    font-family "PT Serif"
    font-size 30px
    font-weight 700
    font-style italic
    line-height 22px

.cartFormTotalRules
    display block
    margin-bottom 20px
    font-family "PT Sans"
    font-size 15px
    font-style italic
    line-height 22px
    color #333
    a
        color #3b6bcd
