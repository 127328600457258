.dateGroup
    display inline-block
    vertical-align middle
    input
        display inline-block
        width 130px
        margin-right 10px
    span
        display inline-block
        vertical-align middle
.datepicker
    margin-top 5px
    width 100%
    max-width 300px
    padding 0
    direction ltr
    &.datepicker-rtl
        direction rtl
        table
            tr
                td
                    span
                        float right
    & > div
        display none
    table
        margin 0
        touch-callout none
        user-select none
        width 100%
        border 1px solid #DDDDDD
        thead
            tr
                border-top 1px solid #DDDDDD
                th
                    font-weight normal
                    height 30px
                    vertical-align middle
                    text-align center
                    padding 5px
                    border 0
                .dow
                    color #B3B3B3
        tr
            td
                &.highlighted
                    background #d9edf7
                    border-radius 0
                &.today
                    &:hover
                        &:hover
                            color #000
                    &.active
                        &:hover
                            color #fff
                span
                    display block
                    text-align center
                    width 23%
                    height 54px
                    line-height 54px
                    float left
                    margin 1%
                    cursor pointer
                    border-radius 4px
                    &:hover
                        background #eeeeee
    .datepicker-switch
        width 145px
    .cw
        font-size 10px
        width 12px
        padding 0 2px 0 5px
        vertical-align middle
    &.dropdown-menu
        position absolute
        top 100%
        left 0
        z-index 1000
        float left
        display none
        min-width 160px
        list-style none
        background-color #ffffff
        border 1px solid #ccc
        border 1px solid rgba(0, 0, 0, 0.2)
        border-radius 5px
        box-shadow 0 5px 10px rgba(0, 0, 0, 0.2)
        background-clip padding-box
        background-clip padding
        background-clip padding-box
        color #333333
        font-size 13px
        line-height 20px
.datepicker-inline
    width 100%

.datepicker-dropdown
    top 0
    left 0
    &:before
        content ''
        display inline-block
        border-left 7px solid transparent
        border-right 7px solid transparent
        border-bottom 7px solid #999999
        border-top 0
        bordercolor rgba(0, 0, 0, 0.2)
        position absolute
    &:after
        content ''
        display inline-block
        border-left 6px solid transparent
        border-right 6px solid transparent
        border-bottom 6px solid #ffffff
        border-top 0
        position absolute
    &.datepicker-orient-left
        &:before
            left 6px
        &:after
            left 7px
    &.datepicker-orient-right
        &:before
            right 6px
        &:after
            right 7px
    &.datepicker-orient-bottom
        &:before
            top -7px
        &:after
            top -6px
    &.datepicker-orient-top
        &:before
            bottom -7px
            border-bottom 0
            border-top 7px solid #999999
        &:after
            bottom -6px
            border-bottom 0
            border-top 6px solid #ffffff

.datepicker td,
.datepicker th
    text-align left
    vertical-align bottom
    width 45px
    height 30px
    font-family Arial, sans-serif
    font-size 13px
    line-height 13px
    border 1px solid #DDDDDD
    padding 0 0 5px 5px
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th
    background-color transparent

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused
    background #eeeeee
    cursor pointer

.datepicker table tr td.old,
.datepicker table tr td.new
    color #999999

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover
    background #CF8080
    color #fff
    cursor default

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover
    background-color #fde19a
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a)
    background-repeat repeat-x
    border-color #fdf59a #fdf59a #fbed50
    border-color rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25)

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled]
    background-color #fdf59a

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active
    background-color #fbf069 \9

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover
    background #eeeeee
    border-radius 0

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover
    background-color #f3d17a
    background-image linear-gradient(to bottom, #f3c17a, #f3e97a)
    background-repeat repeat-x
    border-color #f3e97a #f3e97a #edde34
    border-radius 0

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled]
    background-color #f3e97a

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active
    background-color #efe24b \9

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover
    background-color #9e9e9e
    background-image linear-gradient(to bottom, #b3b3b3, #808080)
    background-repeat repeat-x
    border-color #808080 #808080 #595959
    border-color rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25)
    color #fff
    text-shadow 0 -1px 0 rgba(0, 0, 0, 0.25)

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled]
    background-color #808080

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active
    background-color #666666

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover
    background-color #00ADEF
    color #fff

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled]
    background-color #00ADEF

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active
    background-color #00ADEF

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover
    background none
    color #999999
    cursor default

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover
    background-color #00ADEF
    color #fff

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled]
    background-color #00ADEF

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active
    background-color #00ADEF

.datepicker table tr td span.old,
.datepicker table tr td span.new
    color #999999

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th
    cursor pointer
    border 0
    width auto
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover
    background #eeeeee

.input-append.date .add-on,
.input-prepend.date .add-on
    cursor pointer

.input-append.date .add-on i,
.input-prepend.date .add-on i
    margin-top 3px

.input-daterange
    input
        text-align center
        &:first-child
            border-radius 3px 0 0 3px
            border-radius 3px 0 0 3px
        &:last-child
            border-radius 0 3px 3px 0
            border-radius 0 3px 3px 0
    .add-on
        display inline-block
        width auto
        min-width 16px
        height 20px
        padding 4px 5px
        font-weight normal
        line-height 20px
        text-align center
        text-shadow 0 1px 0 #ffffff
        vertical-align middle
        background-color #eeeeee
        border 1px solid #ccc
        margin-left -5px
        margin-right -5px

// .datepicker.dropdown-menu th,
// .datepicker.datepicker-inline th,
// .datepicker.dropdown-menu td,
// .datepicker.datepicker-inline td
//     padding 0px 0px
