.page
    center(970px)
    padding-top 70px
    margin-bottom 100px
    .pageItem
        margin-bottom 20px
    &Wrap
        center(700px)
        margin-bottom 50px
    &Row
        display block
        clear fix
    &Column
        col(1/2)
h1
    color #333
    font-family "PT Serif"
    font-size 40px
    font-weight 700
    font-style italic
    margin-bottom 40px
    text-align center

h2
    color #333
    font-family "PT Serif"
    font-size 18px
    font-weight 700
    margin-bottom 20px
    font-style normal

h3
    color #333
    font-family "PT Sans"
    font-size 15px
    font-weight 700
    margin-bottom 10px

h4
    color #333
    font-family "PT Sans"
    font-size 18px
    font-style italic
    font-weight 400
    margin-bottom 30px

p
    color #333
    font-family "PT Sans"
    font-size 15px
    font-weight 400
    margin-bottom 20px

a
    color #3b6bcd
    font-family "PT Sans"
    font-size 15px
    font-weight 400
    text-decoration underline
    transition .3s all
    &:hover
        text-decoration none
a.black
    color #333
    font-family "PT Sans"
    font-size 15px
    font-weight 400
    text-decoration underline
    transition .3s all
    &:hover
        text-decoration none

table
    width 100%
    margin-bottom 20px
    thead
        color #333
        font-family "PT Sans"
        font-size 15px
        font-weight 700

        tr
            border-bottom 1px solid #fcd1d3
            td
                padding 20px 10px
    tbody
        color #333
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        tr
            td
                padding 10px 10px
            &:first-child
                td
                    padding-top 20px

ol
    padding 0
    margin 0 0 40px 0
    p
        margin-bottom 10px
    li
        color #333
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 30px

ul
    padding 0
    margin 0 0 40px 0
    list-style none
    p
        margin-bottom 10px
    li
        margin-left 20px
        color #333
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 30px
