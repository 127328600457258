.iconBlue
    svg
        size 21px
        stroke #00ADEF
        fill none
.iconBlack
    svg
        size 21px
        stroke #000
        fill #000
.iconBlackSmall
    svg
        size 11px
        stroke #000
        fill #000
.iconBlueBig
    svg
        size 30px
        stroke #00ADEF
        fill none
