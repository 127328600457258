ks-placeholder()
    if selector() == "&"
        error( "placeholder() must be called from a ruleset!" )
    &::-webkit-input-placeholder
        {block}
    &:-moz-placeholder
        {block}
    &::-moz-placeholder
        {block}
    &:-ms-input-placeholder
        {block}

.selectize-control-outline
    position relative
    //$RECYCLE.BIN/min-width 200px
    &.plugin-drag_drop
        &.multi
            & > .selectize-input
                & > div
                    &.ui-sortable-placeholder
                        visibility visible !important
                        background #f2f2f2 !important
                        background rgba(0, 0, 0, 0.06) !important
                        border 0 none !important
                        box-shadow inset 0 0 12px 4px #ffffff
        .ui-sortable-placeholder
            &::after
                content '!'
                visibility hidden
        .ui-sortable-helper
            box-shadow 0 2px 5px rgba(0, 0, 0, 0.2)
    &.plugin-remove_button
        [data-value]
            position relative
            padding-right 24px !important
            .remove
                z-index 1
                position absolute
                top 0
                right 0
                bottom 0
                width 17px
                text-align center
                font-weight bold
                font-size 12px
                color inherit
                text-decoration none
                vertical-align middle
                display inline-block
                padding 2px 0 0 0
                border-left 1px solid #0073bb
                border-radius 0 2px 2px 0
                box-sizing border-box
                &:hover
                    background rgba(0, 0, 0, 0.05)
        [data-value].active
            .remove
                bordercolor #00578d
        .disabled
            [data-value]
                .remove
                    bordercolor #aaaaaa
                    &:hover
                        background none

.selectize-control-outline
    &.multi
        .selectize-input
            &.has-items
                padding 5px 8px 2px
                padding-left 5px
                padding-right 5px
            & > div
                cursor pointer
                margin 0 3px 3px 0
                padding 2px 6px
                background #1da7ee
                color #ffffff
                border 1px solid #0073bb
                &.active
                    background #92c836
                    color #ffffff
                    border 1px solid #00578d
            &.disabled
                [data-value]
                    color #999
                    text-shadow none
                    background none
                    box-shadow none
                    .remove
                        background none
            [data-value]
                text-shadow 0 1px 0 rgba(0, 51, 83, 0.3)
                border-radius 3px
                background-color #1b9dec
                background-image linear-gradient(to bottom, #1da7ee, #178ee9)
                background-repeat repeat-x
                box-shadow 0 1px 0 rgba(0,0,0,0.2),inset 0 1px rgba(255,255,255,0.03)
            [data-value].active
                background-color #0085d4
                background-image: linear-gradient(to bottom, #008fd8, #0075cf)
                background-repeat repeat-x

.selectize-control-outline
    &.single
        .selectize-input
            border 1px solid #cbcbcb
            color #0c0c0c
            background-image linear-gradient(to top, #eee 0%, transparent 100%)
            background-color white
            border-radius 4px
            cursor default
            input
                +ks-placeholder()
                    color #333
            &:after
                content ' '
                display block
                position absolute
                top calc(50% - 1px)
                right 12px
                size 7px 4px
                background-image inline("iconArrowDown.png")
                background-repeat no-repeat
                transition all .4s
            &:before
                content ' '
                display block
                position absolute
                size 30px
                top 0
                right 0
                //background-image linear-gradient(0deg,  #888888 0%, #717171 100%)
            &.dropdown-active
                &:after
                    top calc(50% - 1px)
                    transform rotate(180deg)
    &.selectize-input
        &.disabled
            opacity 0.5
            background-color #fafafa

.selectize-dropdown-header
    position relative
    padding 5px 8px
    border-bottom 1px solid #d0d0d0
    background #f8f8f8
    border-radius 3px 3px 0 0

.selectize-dropdown-header-close
    position absolute
    right 8px
    top 50%
    color #303030
    opacity 0.4
    margin-top -12px
    line-height 20px
    font-size 20px !important
    &:hover
        color #000000
.selectize-control-outline
    .selectize-dropdown
        position absolute
        margin-top 2px
        z-index 10
        background-color white
        border 1px solid #cbcbcb
        border-radius 4px
        box-shadow 1.5px 2.6px 5px rgba(51, 51, 51, 0.15)
        color #fff
        box-sizing border-box
        &.plugin-optgroup_columns
            .optgroup
                border 0
                float left
                box-sizing border-box
                &:last-child
                    border-right 0 none
                &:before
                    display none
            .optgroup-header
                border-top 0 none

        [data-selectable]
            cursor pointer
            overflow hidden
            color #333
            border 0
            border-radius 4px
            background-color #FFF
            .highlight
                color #00a651
        .optgroup
            //border-top 1px solid #f0f0f0
            &:first-child
                border-top 0 none
                .optgroup-header
                    border-top 0 none
        .optgroup-header
            color #303030
            background #ffffff
            cursor default
            padding-top 7px
            font-weight bold
            font-size 0.85em
        .selected
            color #ed1c24
        .active
            color #00a651
            &.create
                color #495c68
        .create
            color rgba(48, 48, 48, 0.5)


.selectize-dropdown,
.selectize-input,
.selectize-input input
    color #333
    font-family "PT Sans"
    font-size 15px
    font-weight 400
    line-height 40px
    font-style normal
.selectize-input
    .not-full
        input
            +ks-placeholder()
                color #333

.selectize-input,
.selectize-control.single .selectize-input.input-active
    //background-image linear-gradient(0deg, #888888 1%, #717171 97%)
    cursor text
    display inline-block
.selectize-control-outline
    .selectize-input
        padding 0 40px 0 10px
        display inline-block
        height 40px
        min-width 200px
        overflow hidden
        position relative
        z-index 1
        box-sizing border-box
        &.full
            background-color #ffffff
        // &.dropdown-active
        //     &::before
        //         content ' '
        //         display block
        //         position absolute
        //         // background #f0f0f0
        //         // height 1px
        //         // bottom 0
        //         // left 0
        //         //right 0
        & > *
            vertical-align middle
            display inline-block
        & > input
            display inline-block !important
            padding 0 !important
            min-height 0 !important
            max-height none !important
            max-width 0 !important
            margin 0 1px !important
            text-indent 0 !important
            border 0 none !important
            background none !important
            line-height inherit !important
            user-select auto !important
            box-shadow none !important
            &::-ms-clear
                display none
            &:focus
                outline none !important
        &::after
            content ' '
            display block
            clear left

.selectize-input.disabled,
.selectize-input.disabled *
    cursor default !important

.selectize-control.multi .selectize-input.disabled > div,
.selectize-control.multi .selectize-input.disabled > div.active
    color #ffffff
    background #d2d2d2
    //border 1px solid #aaaaaa

.selectize-dropdown [data-selectable],
.selectize-dropdown .optgroup-header
    padding 0 20px
    height 40px

.selectize-dropdown-content
    overflow-y auto
    overflow-x hidden
    max-height 200px
    div
        border-bottom 1px solid #8D8D8D

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input
    cursor pointer

.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input
    cursor text

.selectize-control.multi .selectize-input.disabled [data-value],
.selectize-control.multi .selectize-input.disabled [data-value] .remove
    border-color #e6e6e6

.selectize-control.single .selectize-input,
.selectize-dropdown.single
    border-color #b8b8b8


.error + .selectize-control
    .selectize-input
        border 2px solid #D65454
        padding-top 5px
.valid + .selectize-control
    .selectize-input
        border 0px !important
        padding-top 7px !important

.selectize + label.error
    position absolute
    top 30px
#salonChoise + label.error
    position absolute
    top 30px
.errorRemove + label.error
    display none

.selectize-control.single .selectize-input.dropdown-active
    background-image: linear-gradient(0deg, #717171 0%, #888888 100%);
