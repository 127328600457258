.iradio
    display inline-block
    vertical-align middle
    cursor pointer
    position relative
    size 20px
    background-color #FFF
    border-radius 50%
    border 1px solid #cbcbcb
    margin-right 15px
    margin-top -2px
    &.checked
        border 1px solid #000
        &:after
            display block
            position absolute
            background-color #000
            content ''
            size 9px
            border-radius 5px
            transition all .5s
            top center
            left center
.icheckbox
    display inline-block
    vertical-align middle
    cursor pointer
    position relative
    size 20px
    line-height 20px
    background-color #FFF
    border 1px solid #A6A6A6
    &.checked
        &:after
            display block
            position absolute
            background-repeat no-repeat
            background-position center center
            //background-image inline("iconCheck.svg")
            content ''
            size 13px
            left center
            top center
.icheckbox.error
    border 2px solid #D65454
