.catalog
    center(960px)
    padding-top 70px
.catalogHeader
    display block
    margin-bottom 50px
    text-align center
    clear fix
    color #333
    font-family "PT Serif"
    font-size 40px
    font-weight 700
    font-style italic
    line-height 22px

.catalogMenu
    display block
    text-align center
    margin-bottom 50px
    color #333
    font-family "PT Serif"
    font-size 13px
    font-weight 700
    font-style italic
    a
        display inline-block
        position relative
        color #333
        font-family "PT Serif"
        font-size 13px
        font-weight 700
        font-style italic
        line-height 13px
        margin 0 15px
        padding 0
        // &:after
        //     display inline-block
        //     content '•'
        //     padding-left 15px
        &:last-child
            margin-right 0px
            &:after
                display none
        &:hover
            text-decoration none
    .active
        color #fff
        text-decoration none
        &:after
            display block
            position absolute
            top -3px
            left -10px
            width calc(100% + 20px)
            height 20px
            z-index -1
            content ' '
            background-color #ed1c24
            border-radius 11px
.catalogContent
    display block
    clear fix
.catalogItems
    col(3/4)
.catalogItem
    col(1/3,cycle:3)
    margin-bottom 45px
.catalogItemPhoto
    display block
    text-align center
    position relative
    margin-bottom 10px
    img
        width 200px
        margin-bottom 10px
    .catalogItemPhotoHover
        display block
        opacity 0
        visibility hidden
        transition all .5s
        position absolute
        top 0
        left center
        border-radius 50%
        size 186px
        cursor pointer
        //opacity 0.5
        background-color rgba(#00a651, 0.5)
        .catalogItemPhotoHoverText
            display block
            position absolute
            opacity 1
            left center
            top center
            color white
            font-family "PT Serif"
            font-weight 700
            font-style italic
            line-height 22px
            font-size 60px
            span
                display block
                margin-top 20px
                font-size 15px
                margin-bottom -15px
    &:hover
        .catalogItemPhotoHover
            opacity 1
            visibility visible
.catalogItemName
    display block
    text-align center
    margin-bottom 10px
    a
        color #333
        font-family "PT Serif"
        font-size 18px
        font-weight 700
        font-style italic
        line-height 18px
        border-bottom 1px dashed #333
        text-decoration none
.catalogItemAdd
    display block
    text-align center
    margin-bottom 15px
    color #333
    font-family "PT Sans"
    font-size 13px
    font-style italic
    line-height 17px
    min-height 17px
    clear fix

.catalogItemButton
    display block
    text-align center
    button
        margin-left auto
        margin-right auto
        margin-bottom 15px

.catalogSidebar
    col(1/4)

.fromChief
    display block
    margin-bottom 40px
    clear fix
    p
        display block
        margin-bottom 15px
        font-family "PT Sans"
        font-size 15px
        font-style italic
        line-height 26px
        color #333
        a
            color #3b6bcd


.fromChiefPhoto
    display block
    clear fix
    span
        display block
        float left
        color #333
        font-family "PT Sans"
        font-size 13px
        font-weight 400
        line-height 20px
.fromChiefPhotoAvatar
    display inline-block
    vertical-align middle
    margin-right 10px
    margin-bottom 5px
.fromChiefPhotoSign
    display inline-block
    vertical-align middle

.sidebarRecepts
    display block
    margin-bottom 50px
    clear fix
    a
        display block
        margin-bottom 10px
        color #3b6bcd
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 23px
        text-decoration underline
        padding-left 20px
        &:hover
            text-decoration none
.sidebarReceptsHeader
    a
        display block
        background-image inline("iconColosSmall.png")
        background-repeat no-repeat
        background-position left center
        color #333
        font-family "PT Sans"
        font-size 18px
        font-weight 400
        line-height 36px
        text-decoration underline
        padding-left 20px
        margin-bottom 10px


.catalogDescription
    display block
    margin-bottom 40px
    clear fix

.catalogDescriptionItem
    col(1/3)
    h3
        display block
        margin-bottom 15px
        font-family "PT Sans"
        font-weight 400
        color #333
        font-size 18px
        line-height 22px
    p
        font-family "PT Sans"
        font-weight 400
        color #333
        font-size 15px
        line-height 22px
        span
            display block
            margin-top 10px
            color #ed1c24
