input
    appearance: none !important
ks-placeholder()
    if selector() == "&"
        error( "placeholder() must be called from a ruleset!" )
    &::-webkit-input-placeholder
        {block}
    &:-moz-placeholder
        {block}
    &::-moz-placeholder
        {block}
    &:-ms-input-placeholder
        {block}
.inputGroup
    display block
    position relative
    span
        display none
.inputGroupError
    input
        box-shadow inset 2.1px 2.1px 5px rgba(0, 0, 0, 0.25), 0 0 5px rgba(237, 28, 36, 0.42)
    span
        display inline-block
        position absolute
        right 0
        top calc(50% + 4px)
        text-align right
        transform translateX(100%)
        padding-left 15px
        color #ed1c24
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 22px
.input
    display inline-block
    width 100%
    background-color white
    border 1px solid #cbcbcb
    border-radius 4px
    box-shadow inset 2.1px 2.1px 5px rgba(0, 0, 0, 0.25)
    padding 0 10px
    height 40px
    font-size 15px
    -webkit-appearance: none;

    &:focus, &:active
        outline 0
        box-shadow inset 2.1px 2.1px 5px rgba(0, 0, 0, 0.25), 0 0 5px rgba(0, 166, 81, 0.42)

.textarea
    display inline-block
    width 100%
    background-color white
    border 1px solid #cbcbcb
    border-radius 4px
    box-shadow inset 2.1px 2.1px 5px rgba(0, 0, 0, 0.25)
    padding 10px
    height 100px
    font-size 15px
    -webkit-appearance: none;

    &:focus, &:active
        outline 0
        box-shadow inset 2.1px 2.1px 5px rgba(0, 0, 0, 0.25), 0 0 5px rgba(0, 166, 81, 0.42)
