.spinner
    display inline-block
    min-width 36px
    width 36px
    position relative
    clear fix
    input
        display block
        text-align center
        background-color white
        border 1px solid #cbcbcb
        box-shadow inset 2.1px 2.1px 5px rgba(0, 0, 0, 0.25)
        color #333
        font-family "PT Sans"
        font-size 18px
        font-weight 400
        line-height 22px
        height 40px
        border-radius 0px !important
.spinnerControl
    display block
    position absolute
    width 30px
    background-image linear-gradient(0deg,  #888888 0%, #717171 100%)
    right 0
    top 0
.spinnerUp, .spinnerDown
    display block
    height 17px
    width 36px
    outline 0
    border 0
    background-color #eaeaea
    background-image linear-gradient(to top, rgba(38, 46, 42, 0.04) 0%, transparent 100%)
    color #333
    font-family "PT Sans"
    font-size 13px
    font-style italic
    line-height 17px
    &:hover
        background-color #00a651
        background-image linear-gradient(to top, rgba(71, 77, 74, 0.1) 0%, rgba(221, 221, 221, 0.05) 100%)
        color #FFF
