.quality
    display block

    clear fix

.qualityHeader
    display block
    text-align center
    color #333
    font-family "PT Serif"
    font-size 40px
    font-weight 700
    font-style italic
    line-height 40px
    margin-bottom 79px
.qualityContent
    display block
    margin-bottom 120px
    clear fix
.qualityContentPhoto
    col(7/12)
.qualityContentText
    col(5/12)
    h2
        color #333
        font-family "PT Sans"
        font-size 30px
        font-weight 400
        line-height 39px
        margin-bottom 30px
    p
        color #333
        font-family "PT Sans"
        font-size 15px
        font-weight 400
        line-height 22px

.qualityStandart
    display block
    margin-bottom 140px
    clear fix

.qualityStandartContent
    col(1/2)
    h2
        display block
        margin-bottom 32px
        color #333
        font-family "PT Sans"
        font-size 30px
        font-weight 400
        line-height 39px
        span
            border-bottom 1px solid #333
    p
        display block
        margin-bottom 15px
        font-family "PT Sans"
        color #333
        font-size 15px
        font-style normal
        line-height 22px
        b
            display block
            font-family "PT Serif"
            font-weight 700
            font-style italic
            line-height 22px



.qualityStandartInterview
    col(1/2)
    background-color #fde8e9
    border-radius 4px
    padding 20px

.qualityStandartInterviewPhoto
    col(1/2)
    color #333
    font-family "PT Sans"
    font-size 13px
    font-weight 400
    line-height 22px
.qualityStandartInterviewQoute
    col(1/2)
    color #333
    font-family "PT Sans"
    font-size 15px
    font-style italic
    line-height 26px
