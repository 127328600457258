button
    &:active, &:focus
        outline 0
.buttonGreen
    display block
    background-color #00a651
    background-image linear-gradient(to top, rgba(38, 46, 42, 0.09) 0%, transparent 100%)
    border-radius 5px
    box-shadow 0 2px 2px rgba(22, 25, 24, 0.3)
    border-radius 5px
    border 0
    outline 0
    color white
    font-family "PT Serif"
    font-size 15px
    font-weight 700
    font-style italic
    line-height 45px
    padding 0 30px
    height 45px
    &:hover
        background-color #00a651
        background-image linear-gradient(to top, rgba(71, 77, 74, 0.16) 0%, rgba(221, 221, 221, 0.06) 100%)
        border-radius 5px
        box-shadow 0 2px 2px rgba(22, 25, 24, 0.3)
.buttonGreenBig
    @extend .buttonGreen
    padding 0 110px
.buttonGreenSmall
    @extend .buttonGreen
    line-height 30px
    height 30px
    padding 0 15px
    font-size 13px
.buttonGreenOutline
    display block
    background-color white
    background-image linear-gradient(to top, rgba(38, 46, 42, 0.15) 0%, transparent 100%)
    border 1px solid rgba(#00a651, 0.3)
    border-radius 5px
    box-shadow 0 2px 2px rgba(22, 25, 24, 0.19)
    color #00a651
    font-family "PT Sans"
    font-size 13px
    line-height 22px
    font-weight 400
    padding 0 15px
    height 40px
    &:hover
        background-color #00a651
        border 1px solid #00a651
        background-image linear-gradient(to top, rgba(71, 77, 74, 0.16) 0%, rgba(221, 221, 221, 0.06) 100%)
        box-shadow 0 2px 2px rgba(22, 25, 24, 0.3)
        color #fff
.buttonDelete
    display inline-block
    size 8px
    border 0
    outline 0
    background-image inline("iconCloseSmall.png")
    background-repeat no-repeat
    background-color transparent
    background-position center center
    &:hover
        background-image inline("iconCloseSmallActive.png")
