.slick-slider
    position relative
    display block
    box-sizing border-box
    user-select none
    touch-callout none
    touch-action pan-y
    tap-highlight-color transparent

.slick-list
    position relative
    display block
    overflow hidden
    margin 0
    padding 0
    &:focus
        outline none
    &.dragging
        cursor pointer
        cursor hand

.slick-slider .slick-track,
.slick-slider .slick-list
    transform translate3d(0, 0, 0)

.slick-track
    position relative
    top 0
    left 0
    display block
    &:after
        clear both

.slick-track:before,
.slick-track:after
    display table
    content ''

.slick-loading
    .slick-track
        visibility hidden
    .slick-slide
        visibility hidden

.slick-slide
    display none
    float left
    height 100%
    min-height 1px
    img
        display block
        //width 100%
    &.slick-loading
        img
            display none
    &.dragging
        img
            pointer-events none

[dir='rtl']
    .slick-slide
        float right

.slick-initialized
    .slick-slide
        display block

.slick-vertical
    .slick-slide
        display block
        height auto
        border 1px solid transparent

.slick-arrow
    &.slick-hidden
        display none
.slick-slide
    &:focus,
    &:active
        outline 0
        border 0
.slick-prev
    display block
    position absolute
    top calc(50% - 14px)
    z-index 10000
    left 20px
    size 17px 28px
    font-size 0px
    background-color transparent
    outline 0
    //background-image inline("iconSlickLeft.svg")
    background-repeat no-repeat
    border 0
    &:hover
        //background-image inline("iconSlickLeftActive.svg")
.slick-prev
    display block
    position absolute
    top calc(50% - 14px)
    z-index 10000
    left 20px
    size 24px 45px
    font-size 0px
    background-color transparent
    outline 0
    background-image inline("iconSlickLeft.png")
    background-repeat no-repeat
    border 0
    &:hover
        //background-image inline("iconSlickLeftActive.svg")
.slick-next
    display block
    position absolute
    top calc(50% - 14px)
    z-index 10000
    right 20px
    size 24px 45px
    font-size 0px
    background-color transparent
    outline 0
    background-image inline("iconSlickRight.png")
    background-repeat no-repeat
    border 0
    &:hover
        //background-image inline("iconSlickRightActive.svg")
.slick-disabled
    opacity 0.5
.slick-dots
    display inline-block
    position absolute
    bottom 15px
    left 15px
    list-style none
    width auto
    padding 0 10px
    &:after
        display block
        position absolute
        width 100%
        height 1px
        background-color #79899F
        content ' '
        left 0
        top calc(50% + 1px)
    li
        position relative
        display inline-block
        height 10px
        width 10px
        margin 0 8px
        padding 0
        cursor pointer
        text-align center
        z-index 100
        button
            display inline-block
            position relative
            background-color #fff
            display block
            size 6px
            outline none
            border 0px
            border-radius 50%
            padding 0
            font-size 0
        &.slick-active
            button
                size 10px
                background-color #00ADEF
                &:after
                    display block
                    position absolute
                    content ''
                    size 16px
                    border-radius 50%
                    background-color #00ADEF
                    top -3px
                    left -3px
        &.slick-active + li
            button
                size 10px
