.newsMain
    col(5/8)
.news
    center(960px)
    padding-top 70px
.newsHeader
    display block
    margin-bottom 50px
    text-align center
    clear fix
    color #333
    font-family "PT Serif"
    font-size 40px
    font-weight 700
    font-style italic
    line-height 22px

.newsItems
    col(8/8)
    margin-bottom 50px
.newsItem
    display block
    border 1px solid rgba(#262e2a,0.1)
    padding 40px 30px
    margin-bottom 30px
    font-family "PT Sans"
    font-weight 400
    color #333
    font-size 15px
    line-height 22px
    clear fix
    a
        color #3b6bcd
        font-size 18px
        line-height 47px
.newsItemPhoto
    col(1/3)
    img
        width 100%
.newsItemText
    col(2/3)
.newsItemDate
    font-size 15px
    line-height 22px
    font-family "PT Sans"
    font-weight 400
    color #333
    span
        display inline-block
        line-height 15px
        border-bottom 1px solid #333
.newsPage
    margin-bottom 100px
