.uiItem
    display block
    padding 20px
    margin-left 20px
    clear fix

.uiItemTitle
    font-size 14px
    font-weight bold
    margin-bottom 10px
    clear fix

.uiItemBorder
    width 100%
    border-bottom 3px solid #000

.uiItemHeader
    font-size 20px
    font-weight bold
    padding 20px
    margin-bottom 10px
    clear fix
