.overlay
    display block
    position absolute
    top 0
    left 0
    background-color rgba(#fff,0.7)
    z-index 0
    visibility hidden
    opacity 0
    height 100%
    width 100%
    transition .5s all
.overlayShow
    z-index 1000
    visibility visible
    opacity 1
.popup
    display block
    position fixed
    padding 45px 50px
    top center
    left center
    width 720px
    border 1px solid #ed1c24
    border-radius 4px
    box-shadow 0 2px 10px rgba(0, 0, 0, 0.2)
    background-color #FFF
    z-index 0
    visibility hidden
    opacity 0
    transition .5s all
.popupShow
    z-index 10000
    visibility visible
    opacity 1
.popupClose
    display block
    position absolute
    top 47px
    right 50px
    size 20px
.buttonPopupClose
    display block
    size 20px
    border 0
    outline 0
    background-color transparent
    background-image inline("iconPopupClose.png")
    background-repeat no-repeat
    background-position center center
    transition .3s all
    &:hover
        background-image inline("iconPopupCloseActive.png")

.popupHeader
    display block
    color #333
    font-family "PT Serif"
    font-size 24px
    font-weight 700
    font-style italic
    margin-bottom 10px
.popupText
    color #333
    font-family "PT Sans"
    font-size 18px
    font-weight 400
    a
        color #3b6bcd
        &:hover
            text-decoration none
    span
        display block
        margin-top 10px
        color #333
        font-size 15px
        line-height 22px
        font-family "PT Sans"
        font-weight 400
        b
            font-family "PT Serif"
            font-weight 700
            font-style italic
