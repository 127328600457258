.sliders
    display block
    padding-top 50px
    margin-bottom 120px
.sliderPhoto
    display block
    margin-bottom 40px
    clear fix
    img
        height 330px
        margin-right 20px
.sliderBig
    display block

.sliderBigSlide
    display block
    clear fix
.sliderBigSlidePhoto
    col(1/2)
    padding-left 80px
.sliderBigSlideDescription
    col(1/2)
    h2
        color #333
        font-family "PT Serif"
        font-size 30px
        font-weight 700
        font-style italic
        line-height 39px
        margin-bottom 25px
    p
        display block
        padding-right 50px
        margin-bottom 15px
        color #333
        font-size 15px
        font-family "PT Sans"
        font-style italic
        line-height 22px
        b
            font-family "PT Serif"
            font-weight 700
            font-style italic
            line-height 22px
        &:last-child
            margin-bottom 40px
.sliderBigSlideTime
    display block
    margin-top 25px
    color #333
    font-family "PT Sans"
    font-weight 400
    font-size 15px
    line-height 36px
    background-image inline("iconTime.png")
    background-repeat no-repeat
    background-position left center
    padding-left 35px
    span
        color #ed1c24
        font-family "PT Serif"
        font-weight 700
        font-style italic

.sliderBigSlideLife
    display block
    color #333
    font-family "PT Sans"
    font-weight 400
    font-size 15px
    line-height 36px
    background-image inline("iconLife.png")
    background-repeat no-repeat
    background-position left center
    padding-left 35px
    span
        color #ed1c24
        font-family "PT Serif"
        font-weight 700
        font-style italic

.sliderSmall
    display block
    width 80%
    margin-left auto
    margin-right auto
    clear fix
    .slick-current
        opacity 0.5
        .sliderSmallSlideText
            span
                border-bottom 0

.sliderSmallSlide
    display block
    width 150px
    margin 0 10px
    transition all .3s
    cursor pointer !important
    clear fix

.sliderSmallSlidePhoto
    text-align center
    margin-bottom 10px
    img
        width 150px
.sliderSmallSlideText
    display block
    text-align center
    color #333
    font-family "PT Serif"
    font-size 15px
    font-weight 700
    font-style italic
    span
        display inline-block
        border-bottom 1px dashed #333
